import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import SidebarMenuStyleddiv from './styledComponent'
import MESSAGE from './message'
import { DASHBOARD_TOOLTIP, VIOLATION_TOOLTIP, ZONE_TOOLTIP } from '../../utils'

export default class SidebarMenu extends Component {
  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getTooltipText() {
    const tooltipObject = Object.assign(
      {},
      {
        [DASHBOARD_TOOLTIP]: this.getMessage('dashboard_tooltip'),
        [VIOLATION_TOOLTIP]: this.getMessage('violation_tooltip'),
        [ZONE_TOOLTIP]: this.getMessage('zone_tooltip')
      }
    )
    return tooltipObject[this.props.tooltipText].toUpperCase()
  }

  handleMenuClick = () => {
    this.props.onClick(this.props.path)
  }

  render() {
    let className = this.props.active ? ' active' : ''
    return (
      <Tooltip title={this.getTooltipText()} placement="right">
        <SidebarMenuStyleddiv id={this.props.id} onClick={this.handleMenuClick} ref={(ref) => (this.submenu = ref)}>
          <div className={'sidebar-menu-wrapper' + className}>{this.props.icon}</div>
        </SidebarMenuStyleddiv>
      </Tooltip>
    )
  }
}

SidebarMenu.defaultProps = {
  language: 'EN'
}

SidebarMenu.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.element.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
  language: PropTypes.string,
  path: PropTypes.string
}
