const MESSAGE = {
  EN: {
    dashboard_tooltip: 'dashboard',
    violation_tooltip: 'violation',
    zone_tooltip: 'zone setting'
  },
  TH: {
    dashboard_tooltip: 'แดชบอร์ด',
    violation_tooltip: 'การฝ่าฝืน',
    zone_tooltip: 'การตั้งค่าโซน'
  }
}

export default MESSAGE
