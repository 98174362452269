import styled from 'styled-components'

export default styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  .tooltip-container {
    padding: 10px;
    font-family: 'Prompt', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
  }

  .sidebar-menu-wrapper {
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      background-color: ${(props) => props.theme.jadeGreen};
      box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.5);
    }
  }
`
